.main-content-wrapper-mend {
  * {
    font-weight: 500 !important;
  }
}

.main-content-wrapper-navis {
  * {
    font-weight: 300 !important;
  }

  & .title-navis {
    font-weight: 700 !important;
  }
}

.main-content-wrapper-digit {
  * {
    font-weight: 500 !important;
  }

  & .title-digit {
    font-weight: 600 !important;
  }
}

.main-content-wrapper-amazon {
  * {
    font-weight: 400 !important;
  }

  & .title-amazon {
    font-weight: 500 !important;
  }
}

.main-content-wrapper-zaurus {
  * {
    font-weight: 500 !important;
  }

  & .title-zaurus {
    font-weight: 600 !important;
  }
}
