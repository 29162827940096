@import url('https://fonts.googleapis.com/css2?family=Arima:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans/semibold/OpenSans-Semibold.woff2') format('woff2'),
        url('../fonts/OpenSans/semibold/OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans/bold/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans/bold/OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans/regular/OpenSans.woff2') format('woff2'),
        url('../fonts/OpenSans/regular/OpenSans.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: local('Barlow Regular'), local('Barlow-Regular'), url(https://fonts.gstatic.com/s/barlow/v4/7cHpv4kjgoGqM7E_DMs5ynghnQ.woff2) format('woff2');
}
@font-face {
    font-family: 'ArchivoSemiExpanded';
    src: url('../fonts/ArchivoSemiExpanded/regular/ArchivoSemiExpanded-Medium.ab80282a16bd9f6ce5aa.woff2') format('woff2'),
}