.control-bar {
  padding: 30px 0;
  display: flex;
  align-items: center;

  .control-bar-item {
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
    &.control-bar-center {
      text-align: center;
    }
    &.control-bar-right {
      text-align: right;
    }
  }

  .control-bar-form {
    &__input {
      & input {
        height: 2.5em;
        line-height: normal;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .error {
      // border-bottom: 2px solid red;
      // margin-top: 2px;
    }
  }
}

.MuiOutlinedInput-input-1534 {
  padding: 14.5px 12px !important;
}
.control-bar-left {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  padding-left: 0;
  position: relative;
  // background: #fff;
  // width: calc(50% + 100px);
  // &.readonly {
  //   width: unset;
  // }
  // &.emailRequiredForStart {
  //   display: flex;
  //   flex-direction: row-reverse;
  // }
}

// .control-bar-left:nth-child(1){
//   border-left: solid 2px #c9cbd1;
// }

@media only screen and (max-width: 576px) {
  .formControl-Inputfield{
     margin-top: 18px!important;
  }
}

.MuiGrid-grid-xs-6-1699 {
  padding-right: 2px !important;
}
.MuiGrid-grid-xs-6-1345 {
  padding-right: 2px !important;

}

.control-bar-more {
  margin: 0 0 0 auto;
  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & p {
    margin: 5px;
  }
}

@media (max-width: 1250px) {
  .control-bar-left {
    &.full-bar {
      width: calc(45% + 100px);
    }
  }
}

@media (max-width: 750px) {
  .control-bar-left {
    &.emailRequiredForStart {
      display: flex;
      flex-direction: column-reverse;
      .control-bar-form {
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 750px) {
  .control-bar {
    flex-direction: column;
    &-left {
      width: auto;
      margin: 0 auto 20px;
      &.full-bar {
        width: 100%;
      }
    }
    &-more {
      margin: 0;
    }
    .control-bar-item {
      &.control-bar-right {
        text-align: center;
        margin-top: 20px;
      }
    }
    .control-bar-form {
      flex-wrap: wrap;
      padding: 0;
      background: transparent;

      // &__input {
      //   width: 50%;
      //   background: #fff;
      //   padding: 10px 15px;
      //   border-left: 0;
      //   &:before {
      //     content: "";
      //     position: absolute;
      //     left: 0;
      //     top: 8px;
      //     width: 1px;
      //     height: calc(100% - 16px);
      //     background: #c9cbd1;
      //   }
      //   &:first-child {
      //     &:before {
      //       display: none;
      //     }
      //   }
      // }
      &__btn {
        margin: 10px auto 0;
      }
    }
  }
}
