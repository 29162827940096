@import './fonts.scss';

body {
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    background-color: #f5f5f5!important;
}

// used by ntt-react-ui/src/assets/images/loading-progress.svg
.str0 {stroke:white;stroke-width:10}
.fil1 {fill:#429EDE}
.fil0 {fill:#9CBE39}
.fil2 {fill:#CE3021}
.fil3 {fill:#FFBE08}
